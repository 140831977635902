import React from 'react';
import { motion } from 'framer-motion';

const About = () => {
  return (
    <section className="py-32 px-4 sm:px-6 lg:px-8 xl:pb-64">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="relative"
          >
            <div className="h-[400px] md:h-[600px] lg:h-[930px] rounded-2xl overflow-hidden">
              <img
                src="/images/team-image-1.png"
                alt="Our Team"
                className="w-full h-full object-contain md:object-cover"
              />
            </div>
            <div className="absolute -bottom-6 -right-6 bg-accent text-white p-6 rounded-2xl">
              <p className="text-4xl font-bold">15+</p>
              <p className="text-sm">Expert Team Members</p>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="space-y-6"
          >
            <h2 className="text-4xl font-bold">About Our Company</h2>
            <p className="text-gray-600">
              Based in Bangladesh, we're a dynamic IT solutions provider with a global reach. 
              Our team of 15+ expert developers combines local talent with international expertise 
              through strategic partnerships.
            </p>
            
            <div className="grid grid-cols-2 gap-6">
              <div className="p-4 bg-gray-50 rounded-lg">
                <h4 className="font-bold mb-2">Mission</h4>
                <p className="text-gray-600 text-sm">
                  To deliver innovative and reliable IT solutions that drive business growth
                </p>
              </div>
              <div className="p-4 bg-gray-50 rounded-lg">
                <h4 className="font-bold mb-2">Vision</h4>
                <p className="text-gray-600 text-sm">
                  To be the leading IT partner for businesses worldwide
                </p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About; 