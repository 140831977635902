import React from 'react';
import { motion as m } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const Logistics = () => {
  const navigate = useNavigate();

  const products = [
    {
      image: './images/clutch_logistics.png',
      name: 'Clutch Logistics',
      role: 'Platform / Mobile Application',
      thumbnail: '/images/logo.png'
    },
    {
      image: './images/Product-1.png',
      thumbnail: '/images/Product-2.png'
    },
    {
      image: '/images/Product-3.jpg',
      thumbnail: '/images/Product-3.png'
    }
  ];

  return (
    <div className="min-h-screen bg-white px-4 py-24 md:py-24">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="grid grid-cols-1 py-10 lg:grid-cols-2 gap-16 items-center">
          <div>
            <m.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-6xl font-extrabold mb-8 text-gray-800"
            >
              Clutch
            </m.h1>
            <m.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-6xl font-extrabold text-gray-800"
            >
              Logistics
            </m.h1>
          </div>
          
          <m.p 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
            className="text-xl leading-relaxed text-gray-600"
          >
            Clutch Logistics is a powerful, integrated solution combining a feature-rich logistics web 
            application with the intuitive Clutch Driver App. Designed for exporters and large-scale logistics 
            operations, it offers everything needed to manage assets, schedule transports, and monitor fleets.
          </m.p>
        </div>

        {/* Product Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          {/* Main Image */}
          <div className="relative">
            <m.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.6 }}
              className="relative aspect-[3/4] bg-white rounded-lg overflow-hidden"
            >
              <img 
                src={products[0].image} 
                alt={products[0].name}
                className="w-full h-full object-cover"
              />
              <button className="absolute right-4 bottom-4 w-12 h-12 bg-primary text-white rounded-full flex items-center justify-center hover:bg-primary/90 transition-colors">
                <span>→</span>
              </button>
            </m.div>
            
            {/* Name and Role */}
            <m.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7 }}
              className="mt-4"
            >
              <h3 className="text-xl text-gray-900">{products[0].name}</h3>
              <p className="text-gray-600">{products[0].role}</p>
            </m.div>
          </div>

          {/* Right Side Content */}
          <div className="space-y-16">
            {/* Thumbnails */}
            <m.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8 }}
              className="flex gap-4"
            >
              {products.map((product, index) => (
                <div key={index} className="w-26 h-24 bg-gray-100 rounded-lg overflow-hidden">
                  <img 
                    src={product.thumbnail}
                    alt=""
                    className="w-full h-full object-cover"
                  />
                </div>
              ))}
            </m.div>

            {/* Bottom Text */}
            <m.p 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1 }}
              className="text-xl leading-relaxed text-right text-gray-600"
            >
              The web application enables seamless planning, organization, and execution of transport orders, 
              ensuring you meet deadlines efficiently. Meanwhile, the Clutch Driver App empowers drivers with 
              real-time job assignments, GPS-tracked routes, delivery status updates, and easy reporting—all 
              from their smartphones. This synergy between the web platform and mobile app ensures smooth 
              communication, timely deliveries, and enhanced coordination between dispatch teams and drivers.
            </m.p>

            {/* Request Demo Button */}
            <m.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1.2 }}
              className="flex justify-end"
            >
              <button 
        onClick={() => navigate('/contact')}
        className="btn-primary flex items-center gap-2"
      >
                <span>Request Demo</span>
                <span>→</span>
              </button>
            </m.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logistics; 