import React from 'react';
import { motion } from 'framer-motion';
import Carousel from './Carousel';
import Button from './Button';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const navigate = useNavigate();
  
  const heroSlides = [
    {
      image: '/images/hero-image.png',
      title: 'Clutch Logistics',
      description: 'Custom applications and integrations tailored to your business needs',
      link: '/logistics'
    },
    {
      image: '/images/team-image-1.png',
      title: 'IT Expertise',
      description: 'A team of skilled developers and engineers delivering innovative tech solutions',
      link: '/about'
    },
    {
      image: '/images/booking_img.png',
      title: 'Clutch Booking',
      description: 'Advanced booking and reservation systems',
      link: '/bookings'
    }
  ];
  

  return (
    <section className="min-h-screen flex items-center justify-center px-4 sm:px-2 lg:px-3 pt-24 md:pt-0">
      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-[0.8fr,1.2fr] gap-14 items-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-left"
        >
          <h1 className="text-5xl md:text-6xl font-bold leading-tight">
            Innovative IT Solutions for a
            <span className="text-accent"> Digital World</span>
          </h1>
          <p className="text-gray-600 text-lg mb-8">
            We deliver cutting-edge software development, IT support, and machine learning solutions
            to transform your business.
          </p>
          
          <div className="flex flex-wrap gap-4">
            <button 
              onClick={() => navigate('/contact')}
              className="btn-primary"
            >
              Get Free Consultation
            </button>
            <button 
              onClick={() => navigate('/portfolio')}
              className="px-6 py-3 border-2 border-primary rounded-lg hover:bg-primary hover:text-white transition-all"
            >
              View Portfolio
            </button>
          </div>
        </motion.div>
        
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="relative"
        >
          <div className="relative w-full rounded-2xl overflow-hidden bg-transparent shadow-[0_0_30px_rgba(0,0,0,0.2)]">
            <Carousel slides={heroSlides} />
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;