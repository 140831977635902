import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const projects = [
  {
    title: "Clutch Logistics",
    image: './images/clutch_logistics.png',
    description: `Clutch Logistics is an integrated solution combining a web application with the Clutch Driver App.
     Designed for exporters and large-scale logistics, it streamlines asset management, transport scheduling, and fleet monitoring.
      The web app ensures efficient transport planning, while the Driver App provides real-time updates, GPS-tracking, and easy reporting, 
      enhancing communication and coordination between dispatch teams and drivers.</p>
    `,
    tags: ["Software Development", "Machine Learning", "AWS Infrastructure"],
    website: null,
    internalLink: "/logistics"
  },
  {
    title: "Clutch Booking",
    image: './images/booking_img.png',
    description: `Clutch Booking simplifies booking management for time-sensitive industries. 
    It allows site managers to create customized booking slots, track availability, and optimize scheduling.
     When integrated with Clutch Logistics, it streamlines operations and improves communication across scheduling, 
     transport, and asset management.`,
    tags: ["Software Development", "Web Development", "AWS Infrastructure"],
    website: null,
    internalLink: "/bookings"
  },
  {
    title: "Mahonys Transport Services Website",
    image: "/images/mahonys-website.png",
    description: `We designed and built the Mahonys Transport Services website, 
    creating a modern, responsive platform to streamline their logistics operations. 
    With integrated booking systems and compliance management tools, the website enhances fleet tracking and task coordination.
     Our team ensured a seamless user experience across all devices, including a mobile-friendly menu and an efficient cotton and truck time slot 
     booking system.`,
    tags: ["Web Development", "Software Development"],
    website: "https://mahonystransport.com.au"
  },
  {
    title: "Grainpac Melbourne Website",
    image: "/images/grainpac-website.png",
    description: `We developed the Grainpac Melbourne website to provide an easy-to-use, professional
     interface for their grain packing and logistics services. Our team integrated features like accreditation and certification,
      making it simple for customers to access vital information. The clean design reflects Grainpac's commitment
       to quality, and we optimized the site for a smooth experience across all devices.`,
    tags: ["Web Development"],
    website: "https://www.grainpac.com.au/"
  },
  {
    title: "Clutch Driver Application",
    image: "/images/Mobile_clutch.png",
    description: `The Clutch Driver application is a user-friendly mobile tool designed to enhance communication 
    and efficiency for drivers in the logistics and grain transport industries. It allows drivers to receive real-time job assignments, update
     delivery statuses, track routes, and submit reports directly from their smartphones. With GPS integration and easy-to-use features,
     the Clutch Driver app helps streamline daily operations, ensuring timely deliveries and better coordination with the dispatch team.`,
    tags: ["Software Development", "Machine Learning", "AWS Infrastructure"],
    website: null
  },
  {
    title: "Mahonys Transport ERP System",
    image: "/images/transaction-img.png",
    description: `We developed a custom ERP system for Mahonys Transport using PowerApps.
     This solution enables them to manage orders, process them in the packer's schedule application,
      and track stock levels through the transaction app. The system also automates their packing compliance processes, 
      reducing human error and increasing efficiency across operations.`,
    tags: ["Software Development", "BPO Services"],
    website: null
  }
];


const Portfolio = () => {
  return (
    <section className="section-padding pt-28">
      <div className="max-w-7xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-4">Our Portfolio</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Explore our recent projects and see how we've helped businesses transform their digital presence
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
          {projects.map((project, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="bg-blue-50 rounded-3xl overflow-hidden shadow-lg"
            >
              <div className="aspect-[16/9]">
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="p-8">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-2xl font-bold text-gray-900">{project.title}</h3>
                  {(project.website || project.internalLink) && (
                    project.website ? (
                      <a 
                        href={project.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-12 h-12 bg-accent/80 text-white rounded-full flex items-center justify-center hover:bg-accent/70 transition-colors"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                      </a>
                    ) : (
                      <Link 
                        to={project.internalLink}
                        className="w-12 h-12 bg-accent/80 text-white rounded-full flex items-center justify-center hover:bg-accent/70 transition-colors"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                      </Link>
                    )
                  )}
                </div>
                <p className="text-gray-600 leading-relaxed mb-4">{project.description}</p>
                <div className="flex flex-wrap gap-2">
                  {project.tags.map((tag, tagIndex) => (
                    <span
                      key={tagIndex}
                      className="px-3 py-1 bg-grey-600 text-accent font-semibold text-sm rounded-full"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio; 

