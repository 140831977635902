import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      menuRef.current && 
      !menuRef.current.contains(event.target) && 
      buttonRef.current && 
      !buttonRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <nav className={`fixed w-full bg-white/90 backdrop-blur-sm z-50 
      ${isOpen ? 'mb-[120px] md:mb-0' : 'mb-0'} transition-all duration-300`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-20">
          <div className="flex-shrink-0">
            <Link to="/" className="text-2xl font-bold"><img src='/images/clutch_blue.png' className="h-10 md:h-12"/></Link>
          </div>
          
          {/* Desktop Menu */}
          <div className="hidden md:block">
            <div className="ml-10 flex items-center space-x-8">
              <Link to="/" className="hover:text-accent font-semibold">Home</Link>
              <Link to="/about" className="hover:text-accent font-semibold">About</Link>
              <Link to="/services" className="hover:text-accent font-semibold">Services</Link>
              <Link to="/portfolio" className="hover:text-accent font-semibold">Portfolio</Link>
              <Link to="/contact" className="btn-primary font-semibold">Contact Us</Link>
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              ref={buttonRef}
              onClick={() => setIsOpen(!isOpen)}
              className="p-2"
            >
              <span className="sr-only">Open menu</span>
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
              </svg>
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        <div ref={menuRef} className={`
          md:hidden 
          absolute left-0 right-0 
          bg-white backdrop-blur-sm
          px-4 pt-2 pb-6
          transform transition-all duration-300 ease-in-out
          ${isOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4 pointer-events-none'}
        `}>
          <div className="flex flex-col space-y-4">
            <Link to="/" onClick={handleLinkClick} className="hover:text-accent py-2">Home</Link>
            <Link to="/about" onClick={handleLinkClick} className="hover:text-accent py-2">About</Link>
            <Link to="/services" onClick={handleLinkClick} className="hover:text-accent py-2">Services</Link>
            <Link to="/portfolio" onClick={handleLinkClick} className="hover:text-accent py-2">Portfolio</Link>
            <Link to="/contact" onClick={handleLinkClick} className="btn-primary text-center">Contact Us</Link>
          </div>
        </div>
      </div>

      {/* Bottom Border */}
      <div className="absolute bottom-0 left-0 right-0 h-[2px] bg-gradient-to-r from-gray-300 via-gray-100 to-gray-200" />
    </nav>
  );
};

export default Navbar;
