import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CodeBracketIcon, ServerIcon, CommandLineIcon, CpuChipIcon, CloudIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

// Import projects data from Portfolio
const portfolioProjects = [
  {
    title: "Mahonys Transport Services Website",
    image: "/images/mahonys-website.png",
    description: `We designed and built the Mahonys Transport Services website...`,
    tags: ["Web Development", "Software Development"]
  },
  {
    title: "Grainpac Melbourne Website",
    image: "/images/grainpac-website.png",
    description: `We developed the Grainpac Melbourne website...`,
    tags: ["Web Development"]
  },
  {
    title: "Clutch Logistics",
    image: './images/clutch_logistics.png',
    description: `Clutch Logistics is an integrated solution...`,
    tags: ["Software Development", "Machine Learning", "AWS Infrastructure"]
  },
  {
    title: "Clutch Booking",
    image: './images/booking_img.png',
    description: `Clutch Booking simplifies booking management...`,
    tags: ["Software Development", "Web Development", "AWS Infrastructure"]
  },
  {
    title: "Clutch Driver Application",
    image: "/images/Mobile_clutch.png",
    description: `The Clutch Driver application...`,
    tags: ["Software Development", "Machine Learning", "AWS Infrastructure"]
  },
  {
    title: "Mahonys Transport ERP System",
    image: "/images/transaction-img.png",
    description: `We developed a custom ERP system...`,
    tags: ["Software Development", "BPO Services"]
  }
];

const services = [
  {
    title: "Software Development",
    description: "Custom applications and software solutions designed to meet your unique business requirements, enhancing efficiency and productivity.",
    icon: CodeBracketIcon,
  },
  {
    title: "Web Development",
    description: "Responsive and user-friendly websites built with modern technologies, ensuring a seamless experience across all devices.",
    icon: ServerIcon,
  },
  {
    title: "Machine Learning",
    description: "AI-powered solutions and data-driven insights that automate processes, improve decision-making, and drive business growth.",
    icon: CpuChipIcon,
  },
  {
    title: "BPO Services",
    description: "Outsourcing services for customer support, call centers, and back-office functions, designed to improve operational efficiency and customer satisfaction.",
    icon: CommandLineIcon,
  },
  {
    title: "AWS Infrastructure",
    description: "Cloud infrastructure solutions including deployment, scaling, and management of AWS services to optimize performance and reliability.",
    icon: CloudIcon,
  }
];


const Services = () => {
  const [selectedService, setSelectedService] = useState(null);
  const navigate = useNavigate();

  return (
    <section className="py-32 px-4 sm:px-6 lg:px-8 bg-gray-50">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-4">Our Services</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            We provide comprehensive IT solutions to help your business thrive in the digital age
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
          {services.map((service, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              onClick={() => setSelectedService(selectedService === service.title ? null : service.title)}
              className="bg-white p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all cursor-pointer"
            >
              <service.icon className="h-12 w-12 text-accent mb-4" />
              <h3 className="text-xl font-bold mb-2">{service.title}</h3>
              <p className="text-gray-600">{service.description}</p>
            </motion.div>
          ))}
        </div>

        <AnimatePresence>
          {selectedService && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="mt-16"
            >
              <h4 className="text-2xl font-bold mb-8 text-center">{selectedService} Projects</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {portfolioProjects
                  .filter(project => project.tags.includes(selectedService))
                  .map((project, projectIndex) => (
                    <motion.div
                      key={projectIndex}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: projectIndex * 0.1 }}
                      onClick={() => navigate('/portfolio')}
                      className="bg-white rounded-xl overflow-hidden shadow-md hover:shadow-lg transition-all cursor-pointer"
                    >
                      <div className="h-48 relative">
                        <img
                          src={project.image}
                          alt={project.title}
                          className="w-full h-full object-contain p-2"
                        />
                      </div>
                      <div className="p-4">
                        <h5 className="font-bold mb-2">{project.title}</h5>
                        <div className="flex flex-wrap gap-2">
                          {project.tags.map((tag, tagIndex) => (
                            <span
                              key={tagIndex}
                              className="px-3 py-1 bg-grey-600 text-accent font-semibold text-sm rounded-full"
                            >
                              {tag}
                            </span>
                          ))}
                        </div>
                      </div>
                    </motion.div>
                  ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </section>
  );
};

export default Services; 