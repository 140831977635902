import React from 'react';
import Hero from '../components/Hero';
import Services from '../pages/Services';
import About from '../pages/About';
import Partners from '../components/Partners';

const Home = () => {
  return (
    <main>
      <Hero />
      <Services />
      <Partners />
      <About />
    </main>
  );
};

export default Home; 