import { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';

const Carousel = ({ slides }) => {
  const [positions, setPositions] = useState([]);
  const [isTransitioning, setIsTransitioning] = useState(false);
  
  useEffect(() => {
    const initialPositions = slides.map((_, index) => {
      if (index === 0) return 'left-hidden';
      if (index === 1) return 'left';
      if (index === 2) return 'middle';
      if (index === 3) return 'right';
      return 'right-hidden';
    });
    setPositions(initialPositions);
  }, [slides]);

  const scroll = useCallback((direction) => {
    if (isTransitioning) return;
    
    setIsTransitioning(true);
    setPositions(prev => {
      if (direction === 'prev') {
        const [first, ...rest] = prev;
        return [...rest, first];
      } else {
        const copy = [...prev];
        const last = copy.pop();
        return [last, ...copy];
      }
    });
    
    setTimeout(() => {
      setIsTransitioning(false);
    }, 1000); // Increased transition duration
  }, [isTransitioning]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isTransitioning) {
        scroll('next');
      }
    }, 4000); // Slightly longer interval for better viewing

    return () => clearInterval(interval);
  }, [scroll, isTransitioning]);

  return (
    <div className="relative w-full h-[400px] [perspective:1500px]">
      {/* Navigation */}
      <div className="absolute w-full h-[30px] mt-[170px] z-20 opacity-0 hover:opacity-100 transition-opacity duration-300">
        <button 
          onClick={() => scroll('prev')}
          className="absolute bottom-4 left-4 h-[30px] w-[30px] bg-black/85 rounded-full text-gray-200 
            text-center leading-[26px] cursor-pointer shadow-[inset_0_0_0_1px_rgba(255,255,255,0.5),_0_0_0_1px_rgba(0,0,0,0.7)]
            hover:shadow-[inset_0_0_0_2px_rgba(255,255,255,0.5),_0_0_0_1px_rgba(0,0,0,0.7)]
            transition-all duration-300"
        >
          ‹
        </button>
        <button 
          onClick={() => scroll('next')}
          className="absolute bottom-4 right-4 h-[30px] w-[30px] bg-black/85 rounded-full text-gray-200 
            text-center leading-[26px] cursor-pointer shadow-[inset_0_0_0_1px_rgba(255,255,255,0.5),_0_0_0_1px_rgba(0,0,0,0.7)]
            hover:shadow-[inset_0_0_0_2px_rgba(255,255,255,0.5),_0_0_0_1px_rgba(0,0,0,0.7)]
            transition-all duration-300"
        >
          ›
        </button>
      </div>

      {/* Carousel Stage */}
      <div className="relative w-full h-full [transform-style:preserve-3d]">
        {slides.map((slide, index) => (
          <Link 
            key={slide.image}
            to={slide.link}
            className={`
              absolute w-full h-[400px] rounded-xl
              transition-all duration-1000 ease-out
              [transform-style:preserve-3d]
              before:content-[''] before:absolute before:inset-0 before:rounded-xl
              before:shadow-[0_0_15px_rgba(0,0,0,0.4)]
              after:content-[''] after:absolute after:inset-0 after:rounded-xl
              after:shadow-[inset_0_0_2px_rgba(255,255,255,0.4)]
              [webkit-box-reflect:below_0px_-webkit-gradient(linear,left_top,left_bottom,from(transparent),color-stop(0.85,transparent),to(rgba(255,255,255,0.15)))]
              ${positions[index] === 'left-hidden' && 'opacity-0 -z-10 translate-x-[-600px] translate-z-[-500px] rotate-y-[35deg] scale-[0.3]'}
              ${positions[index] === 'left' && 'opacity-90 translate-x-[-350px] translate-z-[-300px] rotate-y-[35deg] scale-[0.6]'}
              ${positions[index] === 'middle' && 'opacity-100 z-10 translate-x-0 translate-z-0 rotate-y-0 scale-100 shadow-2xl'}
              ${positions[index] === 'right' && 'opacity-90 translate-x-[350px] translate-z-[-300px] rotate-y-[-35deg] scale-[0.6]'}
              ${positions[index] === 'right-hidden' && 'opacity-0 -z-10 translate-x-[600px] translate-z-[-500px] rotate-y-[-35deg] scale-[0.3]'}
              group
              hover:scale-[1.02] hover:shadow-xl
            `}
          >
            <div className="relative w-full h-full rounded-xl overflow-hidden">
              {/* Image */}
              <img 
                src={slide.image} 
                alt={slide.title}
                className="absolute w-full h-full object-cover object-center rounded-xl"
              />
              
              {/* Content Overlay - Modified to be footer-style */}
              <div className={`
                absolute bottom-0 left-0 right-0
                bg-black/70 backdrop-blur-sm
                p-4 transform
                transition-all duration-500 ease-in-out
                group-hover:bg-black/80
                ${positions[index] === 'middle' ? 'pointer-events-auto' : 'pointer-events-none'}
              `}>
                <h3 className="text-lg font-semibold text-accent
                  transform transition-all duration-300
                  group-hover:text-accent"
                >
                  {slide.title}
                </h3>
                
                <p className="text-sm text-gray-200 line-clamp-2
                  transform transition-all duration-300
                  group-hover:text-gray-100"
                >
                  {slide.description}
                </p>
              </div>

              {/* Depth Overlay */}
              <div className={`
                absolute inset-0 pointer-events-none
                ${positions[index] === 'left' && 'bg-black/10'}
                ${positions[index] === 'right' && 'bg-black/10'}
                ${positions[index] === 'left-hidden' && 'bg-black/20'}
                ${positions[index] === 'right-hidden' && 'bg-black/20'}
                transition-opacity duration-800
              `}/>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Carousel; 