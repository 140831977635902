import React from 'react';
import { motion } from 'framer-motion';

const partners = [
  {
    name: "Muktodhara Technology Limited",
    logo: "/images/muktodhara_logo.png",
    description: "Strategic Development Partner"
  },
  {
    name: "Mahonys Transport Services",
    logo: "/images/mahonys_logo.jpg",
    description: "Innovation Partner"
  },
  {
    name: "Grainpac Melbourne",
    logo: "/images/grainpac_logo.png",
    description: "Innovation Partner"
  }
];

const Partners = () => {
  return (
    <section className="pt-20 px-4 sm:px-6 lg:px-8 ">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-4">Strategic Partners</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Collaborating with industry leaders to deliver exceptional solutions
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {partners.map((partner, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all text-center"
            >
              <img
                src={partner.logo}
                alt={partner.name}
                className="h-20 w-auto mx-auto mb-4 grayscale hover:grayscale-0 transition-all"
              />
              <h3 className="text-xl font-bold mb-2">{partner.name}</h3>
              <p className="text-gray-600">{partner.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners; 